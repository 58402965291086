import React from "react"
import { injectIntl } from "gatsby-plugin-intl";
import SEO from "components/seo"
import "./letter.scss";
import Arrow from "images/arrow.inline.svg";
import "intersection-observer";
import { InView } from 'react-intersection-observer';

class Letter extends React.PureComponent {
  state = {
    inView: false
  }

  render() {
    const { intl } = this.props;
    let letterTextCount = 0;
    let letterText = "";

    while (intl.messages[`letter-page.letter-text.${letterTextCount}`]) {
      letterText = letterText + intl.messages[`letter-page.letter-text.${letterTextCount}`];
      if (intl.messages[`letter-page.letter-text.${letterTextCount + 1}`] !== undefined) {
        letterText = letterText + "<br><br>";
      }
      letterTextCount = letterTextCount + 1;
    }
    const firstLetter = letterText.charAt(0);

    return (
      <>
        <SEO title={intl.formatMessage({ id: "letter-page.page-title" })} description={intl.formatMessage({ id: "letter-page.page-description" })} />
        <div className="letter-page">
          <div className="letter-page__container">
            <div className="letter-page__title-container">
              <h1 className="fs--lg color--purple">{intl.formatMessage({ id: "letter-page.title" })}</h1>
            </div>
            <div className="letter-page__content">
              <h1 className="fs--lg fake">{intl.formatMessage({ id: "letter-page.title" })}</h1>
              <div className="letter-page__manchet">
                <h2 dangerouslySetInnerHTML={{ __html: intl.formatMessage({ id: "letter-page.manchet" }) }}></h2>
              </div>
              <div className="letter-page__paragraph">
                <span>{firstLetter}</span>
                <p dangerouslySetInnerHTML={{ __html: letterText.slice(1) }}></p>
              </div>
            </div>
          </div>
          <div className="letter-page__end-text">
            <Arrow className={this.state.inView ? "arrow-inview" : ""} />
            <InView className="end-text" rootMargin="-180px 0px" triggerOnce as="div" onChange={inView => this.setState({ inView })}>
              <p className="fs--sm"><strong>{intl.formatMessage({ id: "letter-page.end-text" })}</strong></p>
            </InView>
          </div>
        </div>
      </>
    )
  }
}

export default injectIntl(Letter)
